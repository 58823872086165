.dropdown {
  position: absolute;
  right: auto;
  top: 0;
  left: 100%;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875 rem;
  min-width: 10 rem;
  padding: 0.5 rem 0;
  list-style: none;
  background-color: #3d729c;
  border-radius: 0.5 rem;
  display: none;
  color: white;
}

.dropdown.show {
  display: block;
  animation: anim 0.3s ease-in-out;
}

.dropdown.dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -10 px;
}
@keyframes anim {
  0% {
    display: none;
    opacity: 0;
  }

  100% {
    display: block;

    opacity: 1;
    transform: scale(1);
  }
}
